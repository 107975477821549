<template>
	<div class="calendar" style="height: calc(100vh - 88px)">
		<v-sheet>
			<v-row class="mx-2">
				<v-col xl="2" lg="3" md="3" sm="3">
					<label class="h1-label" style="margin-top: 13px !important">Filters</label>
					<table style="table-layout: fixed" width="100%">
						<tr>
							<td height="15px"></td>
						</tr>
						<tr>
							<td>
								<label for="calendar-search">Search</label>
								<TextInput
									hide-top-margin
									hide-details
									id="calendar-search"
									placeholder="Search"
									clearable
									v-model="filter.search"
									v-on:click:clear="clearFilter('search')"
									v-on:change="doFilter()"
								></TextInput>
							</td>
						</tr>
						<tr>
							<td height="15px"></td>
						</tr>
						<tr>
							<td>
								<label for="calendar-date">Date</label>
								<DatePicker
									id="calendar-date"
									hide-top-margin
									hide-details
									placeholder="Date"
									clearable
									v-model="calendarFilter.date"
									v-on:click:clear="clearCalendarFilter('date')"
									v-on:change="changeCalendar('date')"
								></DatePicker>
							</td>
						</tr>
						<tr>
							<td height="15px"></td>
						</tr>
						<tr>
							<td>
								<label for="calendar-month">Month / Year</label>
								<v-layout>
									<v-flex md7>
										<SelectInput
											placeholder="Month"
											:items="month_lists"
											item-text="label"
											item-value="value"
											hide-details
											hide-top-margin
											id="calendar-month"
											clearable
											v-model="calendarFilter.month"
											v-on:click:clear="clearCalendarFilter('month')"
											v-on:change="changeCalendar('month')"
										></SelectInput>
									</v-flex>
									<v-flex md5>
										<SelectInput
											placeholder="Year"
											:items="year_lists"
											item-text="label"
											item-value="value"
											hide-details
											hide-top-margin
											id="calendar-year"
											clearable
											v-model="calendarFilter.year"
											v-on:click:clear="clearCalendarFilter('year')"
											v-on:change="changeCalendar('year')"
										></SelectInput>
									</v-flex>
								</v-layout>
							</td>
						</tr>
						<tr>
							<td height="15px"></td>
						</tr>
						<tr v-if="false">
							<td>
								<label for="calendar-engineer">Engineer</label>
								<SelectInput
									placeholder="Engineer"
									:items="engineer_lists"
									item-text="text"
									item-value="value"
									hide-details
									hide-top-margin
									id="calendar-engineer"
									clearable
									v-model="filter.engineer"
									v-on:click:clear="clearFilter('engineer')"
									v-on:change="doFilter()"
									multiple
								></SelectInput>
							</td>
						</tr>
						<tr v-if="false">
							<td height="15px"></td>
						</tr>
						<tr v-if="false">
							<td>
								<label id="calendar-subcon">Subcon</label>
								<SelectInput
									placeholder="Subcon"
									:items="subcon_lists"
									item-text="text"
									item-value="value"
									hide-details
									hide-top-margin
									id="calendar-subcon"
									clearable
									v-model="filter.subcon"
									v-on:click:clear="clearFilter('subcon')"
									v-on:change="doFilter()"
									multiple
								></SelectInput>
							</td>
						</tr>
						<tr v-if="false">
							<td height="15px"></td>
						</tr>
						<tr v-if="false">
							<td>
								<label for="calendar-calibration-due-date">Calibration Due Date</label>
								<DatePicker
									id="calendar-calibration-due-date"
									hide-top-margin
									hide-details
									placeholder="Calibration Due Date"
									clearable
									v-model="filter.calibration_date"
									v-on:click:clear="clearFilter('calibration_date')"
									v-on:change="doFilter()"
								></DatePicker>
							</td>
						</tr>
						<tr v-if="false">
							<td height="15px"></td>
						</tr>
						<tr v-if="false">
							<td>
								<label for="calendar-onsite-in-lab">Onsite/In Lab</label>
								<SelectInput
									placeholder="Onsite/In Lab"
									:items="type_lists"
									item-text="label"
									item-value="value"
									hide-details
									hide-top-margin
									id="calendar-onsite-in-lab"
									clearable
									v-model="filter.onsite_inlab"
									v-on:click:clear="clearFilter('onsite_inlab')"
									v-on:change="doFilter()"
									multiple
								></SelectInput>
							</td>
						</tr>
					</table>
				</v-col>
				<v-col xl="10" lg="9" md="9" sm="9">
					<div class="d-flex">
						<v-btn icon class="ma-2" v-on:click="$refs.calendar.prev()">
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
						<label v-if="$refs.calendar" class="h1-label mx-2">{{ $refs.calendar.title }}</label>
						<v-btn icon class="ma-2" v-on:click="$refs.calendar.next()">
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-4 white--text"
							:outlined="is_today"
							depressed
							tile
							class="my-2"
							v-on:click="jumpToDay({ date: null })"
						>
							Today
						</v-btn>
					</div>
					<v-calendar
						style="min-height: calc(100vh - 210px)"
						ref="calendar"
						v-model="value"
						:type="type"
						:events="events"
						:event-overlap-mode="mode"
						:event-overlap-threshold="30"
						:event-color="getEventColor"
						v-on:change="getEvents"
						v-on:click:more="viewMore($event)"
						v-on:click:date="jumpToDay($event)"
						v-on:click:event="jumpToEvent($event)"
						color="blue darken-4 white--text"
					></v-calendar>
					<v-menu
						top
						v-model="selectedOpen"
						:close-on-content-click="false"
						:activator="selectedElement"
						left
					>
						<v-card min-width="300px" max-width="300px" flat>
							<v-card-text>
								<div v-if="event_more_loading" class="text-center">
									<v-progress-circular
										:size="70"
										:width="7"
										color="blue darken-4"
										indeterminate
									></v-progress-circular>
								</div>
								<template v-else>
									<v-chip
										v-for="(row, index) in more_events"
										:key="index"
										:color="`${row.color} white--text`"
										label
										class="mb-1"
										v-on:click="jumpToEvent({ event: { uuid: row.uuid } })"
									>
										<b class="text-uppercase mr-2">{{ formatTime(row.start) }}</b> {{ row.name }}
									</v-chip>
								</template>
							</v-card-text>
						</v-card>
					</v-menu>
				</v-col>
			</v-row>
		</v-sheet>
		<AssetServiceDetail
			:uuid="detail_uuid"
			:dialog="detail_dialog"
			v-on:close="detail_dialog = false"
		></AssetServiceDetail>
	</div>
</template>
<script>
import AssetServiceDetail from "@/view/components/Asset-Service-Detail";
import SelectInput from "@/view/components/SelectInput";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
import ApiService from "@/core/services/api.service";
import MomentJS from "moment-timezone";
import { mapGetters } from "vuex";
import ObjectPath from "object-path";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "calendar",
	title: "Calendar",
	data() {
		return {
			type: "month",
			mode: "stack",
			value: MomentJS().format("YYYY-MM-DD"),
			events: [],
			year_lists: [],
			engineer_lists: [],
			subcon_lists: [],
			month_lists: [
				{ label: "January", value: "january" },
				{ label: "February", value: "february" },
				{ label: "March", value: "march" },
				{ label: "April", value: "april" },
				{ label: "May", value: "may" },
				{ label: "June", value: "june" },
				{ label: "July", value: "july" },
				{ label: "August", value: "august" },
				{ label: "September", value: "september" },
				{ label: "October", value: "october" },
				{ label: "November", value: "november" },
				{ label: "December", value: "december" },
			],
			type_lists: [
				{ label: "On Site", value: "on-site" },
				{ label: "In Lab", value: "in-lab" },
			],
			selectedOpen: false,
			selectedElement: null,
			filter: {
				search: null,
				date: null,
				month: null,
				year: null,
				engineer: null,
				subcon: null,
				calibration_date: null,
				onsite_inlab: null,
			},
			calendarFilter: {
				date: null,
				month: null,
				year: null,
			},
			event_more_loading: false,
			event_loading: false,
			detail_dialog: false,
			detail_uuid: null,
			more_events: [],
		};
	},
	components: {
		AssetServiceDetail,
		TextInput,
		SelectInput,
		DatePicker,
	},
	methods: {
		viewMore({ date, nativeEvent }) {
			if (this.event_more_loading) {
				return false;
			}

			const open = () => {
				this.selectedElement = nativeEvent.target;
				requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
			};

			if (this.selectedOpen) {
				this.selectedOpen = false;
				requestAnimationFrame(() => requestAnimationFrame(() => open()));
			} else {
				open();
			}

			nativeEvent.stopPropagation();

			const startOfMonth = MomentJS(date).startOf("month").format("YYYY-MM-DD");
			const endOfMonth = MomentJS(date).endOf("month").format("YYYY-MM-DD");

			const min = new Date(`${startOfMonth}T00:00:00`);
			const max = new Date(`${endOfMonth}T23:59:59`);

			this.more_events = [];

			const filter = {
				"calendar-min": min,
				"calendar-max": max,
				"calendar-date": date,
			};

			this.event_more_loading = true;

			ApiService.query("dashboard/services", filter)
				.then((output) => {
					this.more_events = ObjectPath.get(output, "data", []);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.event_more_loading = false;
				});
		},
		clearCalendarFilter(param) {
			this.calendarFilter[param] = null;
		},
		changeCalendar(type) {
			let calendar_date = [];

			if (type == "date") {
				if (this.calendarFilter.date) {
					this.value = this.calendarFilter.date;
					this.$refs.calendar.checkChange();
					return false;
				}
			}

			this.clearCalendarFilter("date");

			calendar_date.push(MomentJS().format("DD"));

			if (this.calendarFilter.month) {
				calendar_date.push(this.calendarFilter.month);
			} else {
				calendar_date.push(MomentJS().format("MMMM"));
			}

			if (this.calendarFilter.year) {
				calendar_date.push(this.calendarFilter.year);
			} else {
				calendar_date.push(MomentJS().format("YYYY"));
			}

			this.value = MomentJS(calendar_date.join("-"), "DD-MMMM-YYYY").format("YYYY-MM-DD");

			this.$refs.calendar.checkChange();
		},
		clearFilter(param) {
			this.filter[param] = null;
			const today = ObjectPath.get(this.$refs, "calendar.today");
			const startOfMonth = MomentJS(today).startOf("month").format("YYYY-MM-DD");
			const endOfMonth = MomentJS(today).endOf("month").format("YYYY-MM-DD");
			this.getEvents({ start: { date: startOfMonth }, end: { date: endOfMonth } });
		},
		doFilter() {
			const today = ObjectPath.get(this.$refs, "calendar.today");
			const startOfMonth = MomentJS(today).startOf("month").format("YYYY-MM-DD");
			const endOfMonth = MomentJS(today).endOf("month").format("YYYY-MM-DD");
			this.getEvents({ start: { date: startOfMonth }, end: { date: endOfMonth } });
		},
		jumpToDay({ date }) {
			this.clearCalendarFilter("date");
			this.clearCalendarFilter("month");
			this.clearCalendarFilter("year");
			this.clearCalendarFilter("search");
			this.clearFilter("engineer");
			this.clearFilter("subcon");
			this.clearFilter("calibration_date");
			this.clearFilter("onsite_inlab");
			if (!date) {
				this.value = MomentJS().format("YYYY-MM-DD");
				return true;
			}
			this.value = date;
		},
		jumpToEvent(param) {
			this.detail_uuid = ObjectPath.get(param, "event.uuid");
			if (this.detail_uuid) {
				this.$nextTick(() => {
					this.detail_dialog = true;
				});
			}
		},
		getEvents({ start, end }) {
			const min = new Date(`${start.date}T00:00:00`);
			const max = new Date(`${end.date}T23:59:59`);

			this.events = [];

			const filter = {
				"calendar-min": min,
				"calendar-max": max,
				"calendar-search": this.filter.search,
				"calendar-date": this.filter.date,
				"calendar-month": this.filter.month,
				"calendar-year": this.filter.year,
				"calendar-engineer": this.filter.engineer,
				"calendar-subcon": this.filter.subcon,
				"calendar-calibration-date": this.filter.calibration_date,
				"calendar-onsite-inlab": this.filter.onsite_inlab,
			};

			this.event_loading = true;

			ApiService.query("dashboard/services", filter)
				.then((output) => {
					this.events = ObjectPath.get(output, "data", []);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.event_loading = false;
				});
		},
		getEventColor(event) {
			return event.color;
		},
	},
	mounted() {
		let two_year = MomentJS().subtract(1, "year").format("YYYY");
		this.year_lists.push({ label: two_year, value: two_year });
		let three_year = MomentJS().format("YYYY");
		this.year_lists.push({ label: three_year, value: three_year });
		let four_year = MomentJS().add(1, "year").format("YYYY");
		this.year_lists.push({ label: four_year, value: four_year });
		this.engineer_lists = this.localDB("engineers", []);
		this.subcon_lists = this.localDB("subcons", []);
	},
	computed: {
		...mapGetters(["localDB"]),
		is_today() {
			return this.value != MomentJS().format("YYYY-MM-DD");
		},
	},
};
</script>
