<template>
	<div class="px-4" :class="{ 'service-form-readonly': readonly }">
		<br />
		<table width="100%" class="detail-table scattered-table" style="table-layout: fixed">
			<tr>
				<th width="20%" class="pt-3">Asset ID</th>
				<td width="30%" class="px-4">
					<TextInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.asset_id"
						hide-details
						placeholder="Asset ID"
					></TextInput>
				</td>
				<th width="20%" class="pt-3">Manufacturer</th>
				<td width="30%" class="px-4">
					<TextInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.manufacturer"
						hide-details
						placeholder="Manufacturer"
					></TextInput>
				</td>
			</tr>
			<tr>
				<th width="20%" class="pt-3">Instrument</th>
				<td width="30%" class="px-4">
					<TextInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.instrument"
						hide-details
						placeholder="Instrument"
					></TextInput>
				</td>
				<th width="20%" class="pt-3">Model No.</th>
				<td width="30%" class="px-4">
					<TextInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.model_no"
						hide-details
						placeholder="Model No."
					></TextInput>
				</td>
			</tr>
			<tr>
				<th width="20%" class="pt-3">Date of calibration</th>
				<td width="30%" class="px-4">
					<DatePicker
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.date_of_calibration"
						hide-details
						placeholder="Date of calibration"
					></DatePicker>
				</td>
				<th width="20%" class="pt-3">Serial No.</th>
				<td width="30%" class="px-4">
					<TextInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.serial_no"
						hide-details
						placeholder="Serial No."
					></TextInput>
				</td>
			</tr>
			<tr>
				<th width="20%"></th>
				<td width="30%" class="px-4"></td>
				<th width="20%" class="pt-3">Location</th>
				<td width="30%" class="px-4">
					<TextInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.location"
						hide-details
						placeholder="Location"
					></TextInput>
				</td>
			</tr>
			<tr>
				<th width="20%" class="pt-3">As found</th>
				<td width="80%" colspan="3" class="px-4">
					<RadioInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.as_found_status"
						custom-class="pt-0 mt-0"
						color="blue darken-4"
						row
						:items="[
							{ label: 'Yes', value: 'yes' },
							{ label: 'No', value: 'no' },
						]"
						hide-details
					></RadioInput>
				</td>
			</tr>
			<tr v-if="service_form.as_found_status == 'yes'">
				<th width="20%" valign="top" class="pt-3">As found if YES</th>
				<td width="80%" colspan="3" class="px-4">
					<TextAreaInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.as_found_status_reason"
						placeholder="As found if YES (up to 200 characters)"
						hide-details
					></TextAreaInput>
				</td>
			</tr>
			<tr>
				<th width="20%" class="pt-3">As found</th>
				<td width="80%" colspan="3" class="px-4">
					<RadioInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.as_found_result"
						color="blue darken-4"
						row
						:items="[
							{ label: 'Pass', value: 'pass' },
							{ label: 'Fail', value: 'fail' },
						]"
						hide-details
					></RadioInput>
				</td>
			</tr>
			<tr v-if="service_form.as_found_result == 'fail'">
				<th width="20%" valign="top" class="pt-3">As found if Fail</th>
				<td width="80%" colspan="3" class="px-4">
					<TextAreaInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.as_found_result_reason"
						placeholder="As found if Fail (up to 200 characters)"
						hide-details
					></TextAreaInput>
				</td>
			</tr>
			<tr>
				<th width="20%" class="pt-3">Adjustment</th>
				<td width="80%" colspan="3" class="px-4">
					<RadioInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.adjustment_status"
						color="blue darken-4"
						row
						:items="[
							{ label: 'Yes', value: 'yes' },
							{ label: 'No', value: 'no' },
						]"
						hide-details
					></RadioInput>
				</td>
			</tr>
			<tr v-if="service_form.adjustment_status == 'yes'">
				<th width="20%" valign="top" class="pt-3">Adjustment if Yes</th>
				<td width="80%" colspan="3" class="px-4">
					<TextAreaInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.adjustment_status_reason"
						placeholder="Adjustment if Yes (up to 200 characters)"
						hide-details
					></TextAreaInput>
				</td>
			</tr>
			<tr>
				<th width="20%" class="pt-3">As Left</th>
				<td width="80%" colspan="3" class="px-4">
					<RadioInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.as_left_status"
						color="blue darken-4"
						row
						:items="[
							{ label: 'Yes', value: 'yes' },
							{ label: 'No', value: 'no' },
						]"
						hide-details
					></RadioInput>
				</td>
			</tr>
			<tr v-if="service_form.as_left_status == 'yes'">
				<th width="20%" valign="top" class="pt-3">As Left if Yes</th>
				<td width="80%" colspan="3" class="px-4">
					<TextAreaInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.as_left_status_reason"
						placeholder="As Left if Yes (up to 200 characters)"
						hide-details
					></TextAreaInput>
				</td>
			</tr>
			<tr>
				<th width="20%" class="pt-3">Ad-Hoc Cal</th>
				<td width="80%" colspan="3" class="px-4">
					<RadioInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.ad_hoc_cal_status"
						color="blue darken-4"
						row
						:items="[
							{ label: 'Yes', value: 'yes' },
							{ label: 'No', value: 'no' },
						]"
						hide-details
					></RadioInput>
				</td>
			</tr>
			<tr>
				<th width="20%" class="pt-3">
					Performed By<br /><span class="text--secondary">(Engineer Name)</span>
				</th>
				<td width="30%" class="px-4">
					<TextInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.engineer_name"
						hide-details
						placeholder="Performed By (Engineer Name)"
					></TextInput>
				</td>
				<th width="20%" class="pt-3">
					Acknowledgment<br /><span class="text--secondary">(Engineer Signature)</span>
				</th>
				<td
					rowspan="3"
					width="30%"
					class="px-4 signature-loaded"
					id="engineer-signature"
					style="position: relative"
				>
					<template v-if="readonly">
						<img
							style="width: 300px"
							v-if="service_form.engineer_sign"
							:src="service_form.engineer_sign"
							alt="engineer-signature"
						/>
					</template>
					<template v-else>
						<div class="engineer-signature dashed-border-light-grey" style="background-color: #ddd">
							<canvas ref="engineer-signature"></canvas>
						</div>
						<v-btn
							style="bottom: 2px; position: absolute; right: 18px"
							icon
							small
							v-on:click="clear_signature()"
						>
							<v-icon>mdi-close-circle-outline</v-icon>
						</v-btn>
					</template>
				</td>
			</tr>
			<tr>
				<th width="20%" class="pt-3">Service Start / End Time</th>
				<td class="px-4">
					<div style="max-width: 350px" class="d-flex">
						<div class="mr-2">
							<TimePicker
								:readonly="readonly"
								v-on:change="emit_value()"
								v-model="service_form.service_start_date_time"
								placeholder="Start Time"
								hide-details
							></TimePicker>
						</div>
						<div class="ml-2">
							<TimePicker
								:readonly="readonly"
								v-on:change="emit_value()"
								v-model="service_form.service_end_date_time"
								placeholder="End Time"
								hide-details
							></TimePicker>
						</div>
					</div>
				</td>
				<td class="px-4"></td>
			</tr>
			<tr>
				<th width="20%" class="pt-3">Status</th>
				<td width="80%" colspan="2" class="px-4">
					<RadioInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.status"
						color="blue darken-4"
						row
						:items="[
							{ label: 'Complete', value: 'complete' },
							{ label: 'Incomplete', value: 'incomplete' },
						]"
						hide-details
					></RadioInput>
				</td>
			</tr>
			<tr v-if="service_form.status == 'incomplete'">
				<th width="20%" valign="top" class="pt-3">Input reason if INCOMPLETE</th>
				<td width="80%" colspan="3" class="px-4">
					<TextAreaInput
						:readonly="readonly"
						v-on:change="emit_value()"
						v-model="service_form.status_reason"
						placeholder="Input reason if INCOMPLETE (up to 200 characters)"
						hide-details
					></TextAreaInput>
				</td>
			</tr>
		</table>
		<br />
		<br />
	</div>
</template>

<script>
// import DateTimePicker from "@/view/components/DateTimePicker";
import TimePicker from "@/view/components/TimePicker";
import DatePicker from "@/view/components/DatePicker";
import TextAreaInput from "@/view/components/TextAreaInput";
import RadioInput from "@/view/components/RadioInput";
import TextInput from "@/view/components/TextInput";
import SignaturePad from "signature_pad";
import MomentJS from "moment-timezone";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "service-form-template",
	model: {
		prop: "value",
		event: "change",
	},
	data() {
		return {
			init_signature: false,
			eng_signature: null,
			service_form: {
				asset_id: null,
				instrument: null,
				date_of_calibration: null,
				manufacturer: null,
				model_no: null,
				serial_no: null,
				location: null,
				as_found_status: "no",
				as_found_status_reason: null,
				as_found_result: "pass",
				as_found_result_reason: null,
				adjustment_status: "no",
				adjustment_status_reason: null,
				as_left_status: "no",
				as_left_status_reason: null,
				ad_hoc_cal_status: "no",
				engineer_name: null,
				engineer_sign: null,
				service_start_date_time: null,
				service_end_date_time: null,
				status: "complete",
				status_reason: null,
			},
		};
	},
	props: {
		readonly: {
			type: Boolean,
			default: false,
		},
		value: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	components: {
		TimePicker,
		TextAreaInput,
		RadioInput,
		DatePicker,
		TextInput,
	},
	methods: {
		emit_value() {
			if (!this.readonly) {
				this.service_form.engineer_sign = null;
				if (this.eng_signature) {
					this.service_form.engineer_sign = this.eng_signature.toDataURL();
				}
			}
			this.$emit("input", this.service_form);
			this.$emit("change", this.service_form);
		},
		clear_signature() {
			if (this.eng_signature) {
				this.eng_signature.clear();
			}
			this.service_form.engineer_sign = null;
			this.$emit("input", this.service_form);
			this.$emit("change", this.service_form);
		},
		init_template() {
			this.service_form = {
				asset_id: this.value.asset_id,
				instrument: this.value.instrument,
				date_of_calibration: this.value.date_of_calibration,
				manufacturer: this.value.manufacturer,
				model_no: this.value.model_no,
				serial_no: this.value.serial_no,
				location: this.value.location,
				as_found_status: this.value.as_found_status,
				as_found_status_reason: this.value.as_found_status_reason,
				as_found_result: this.value.as_found_result,
				as_found_result_reason: this.value.as_found_result_reason,
				adjustment_status: this.value.adjustment_status,
				adjustment_status_reason: this.value.adjustment_status_reason,
				as_left_status: this.value.as_left_status,
				as_left_status_reason: this.value.as_left_status_reason,
				ad_hoc_cal_status: this.value.ad_hoc_cal_status,
				engineer_name: this.value.engineer_name,
				engineer_sign: this.value.engineer_sign,
				service_start_date_time: this.value.service_start_date_time,
				service_end_date_time: this.value.service_end_date_time,
				status: this.value.status,
				status_reason: this.value.status_reason,
			};

			if (!this.service_form.service_start_date_time) {
				this.service_form.service_start_date_time = MomentJS().format("HH:mm");
			}

			if (!this.service_form.service_end_date_time) {
				this.service_form.service_end_date_time = MomentJS().format("HH:mm");
			}
		},
		initialize_signature() {
			const _this = this;
			setTimeout(function () {
				let ecanvas = _this.$refs["engineer-signature"];
				let eparentDiv = document.getElementById("engineer-signature");
				let eparentWidth = eparentDiv.offsetWidth - 32;
				let eparentHeight = eparentDiv.offsetHeight;
				ecanvas.setAttribute("width", eparentWidth);
				ecanvas.setAttribute("height", eparentHeight);
				_this.eng_signature = new SignaturePad(ecanvas);
				_this.eng_signature.addEventListener("endStroke", () => {
					_this.emit_value();
				});
				_this.init_signature = true;
			}, 500);
		},
	},
	beforeDestroy() {
		if (this.eng_signature) {
			this.eng_signature.off();
		}
	},
	mounted() {
		const _this = this;
		_this.init_template();
		if (!_this.readonly) {
			_this.initialize_signature();
		}
	},
};
</script>
