<template>
	<Dialog :dialog="detailDialog" :dialog-width="dialogWidth" dense>
		<template v-slot:title>
			<v-layout>
				<v-flex md8 class="my-auto d-flex">
					<div class="d-flex align-items-center">
						<span class="mr-4">{{ service.service_type }} Service</span>
						<Chip
							tooltip
							tooltip-text="Service #"
							class="mr-4"
							:text="service.barcode"
							color="blue darken-4 white--text"
						></Chip>
						<Chip
							tooltip
							tooltip-text="Service By"
							class="mr-4"
							:text="service.service_by_formatted"
							color="blue darken-4 white--text"
						></Chip>
						<Chip
							tooltip
							tooltip-text="Service Type"
							class="mr-4"
							:text="service.service_type_formatted"
							:color="service.service_type == 'on-site' ? 'brown darken-4' : 'blue-grey darken-4'"
						></Chip>
						<Chip
							tooltip
							tooltip-text="Invoiced"
							v-if="service.is_invoiced == 1"
							class="mr-4"
							text="Invoiced"
							color="orange darken-2"
						></Chip>
						<Chip
							v-if="service.status_relation"
							tooltip
							tooltip-text="Status"
							class="mr-4"
							:text="service.status_relation.text"
							:color="service.status_relation.color"
						></Chip>
					</div>
				</v-flex>
				<v-flex md4 class="text-right">
					<template v-if="getPermission('invoice::create')">
						<v-btn
							v-if="
								service.is_acknowledged == 1 && service.acknowledge_status == 1 && service.is_invoiced == 0
							"
							v-on:click="convert_to_invoice()"
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							><v-icon small left>mdi-file-refresh-outline</v-icon>Invoice</v-btn
						>
					</template>
					<template v-if="service.pdf_url">
						<v-btn
							v-on:click="download_pdf()"
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							><v-icon small left>mdi-file-download-outline</v-icon>Download</v-btn
						>
					</template>
					<v-btn
						v-on:click="
							detailDialog = false;
							serviceTab = 'detail';
							$emit('close', true);
						"
						class="ml-2"
						depressed
						tile
						><v-icon small left>mdi-close</v-icon>Close</v-btn
					>
				</v-flex>
			</v-layout>
		</template>
		<template v-slot:body>
			<div>
				<v-tabs
					v-model="serviceTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
					style="position: sticky; top: 0; z-index: 99"
				>
					<v-tab href="#detail">
						<span class="tab-svg-icon mr-2">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/services.svg')" />
							<!--end::Svg Icon-->
						</span>
						Details
					</v-tab>
					<v-tab v-if="service.can_view" href="#service-form-detail">
						<v-icon small left>mdi-file-chart-outline</v-icon> Service Form
					</v-tab>
					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab>
					<v-tab href="#comment"> <v-icon small left>mdi-comment-multiple</v-icon> Comments</v-tab>
				</v-tabs>
				<v-tabs-items v-model="serviceTab" style="min-height: calc(100vh - 300px)">
					<v-tab-item value="detail">
						<div class="mx-4 mb-4">
							<div class="overflow-y">
								<v-expansion-panels v-model="expansion_panel" mandatory>
									<v-expansion-panel>
										<v-expansion-panel-header>
											<div class="d-flex align-items-center">
												<span class="font-level-3-bold mr-4">Service</span>
												<Chip
													tooltip
													tooltip-text="Service #"
													class="mr-4"
													:text="ObjectKey(service, 'barcode', 'N/A')"
													color="blue darken-4 white--text"
												></Chip>
											</div>
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<table
												width="100%"
												class="detail-table scattered-table mt-3"
												style="table-layout: fixed"
											>
												<tr>
													<th class="p-2" width="250px">Service Type</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="service_type_formatted"
															label="service type"
														></ShowValue>
													</td>
													<th class="p-2" width="250px">Service Start Date (expected)</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="expected_start_date_formatted"
															label="service start date (expected)"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="250px">Service Performed by</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="service_by_formatted"
															label="Service Performed by"
														></ShowValue>
														(<ShowValue
															:object="service"
															v-if="service.service_by == 1"
															object-key="engineer.display_name"
															label="engineer"
														></ShowValue>
														<ShowValue
															:object="service"
															v-else
															object-key="subcon.display_name"
															label="subcon"
														></ShowValue
														>)
													</td>
													<th class="p-2" width="250px">Service End Date (expected)</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="expected_end_date_formatted"
															label="Service End Date (expected)"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="250px">Created By</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="created_by.display_name"
															label="Created By"
														></ShowValue>
													</td>
													<th class="p-2" width="250px">Service Start Date (actual)</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="actual_start_date_formatted"
															label="service start date (actual)"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="250px">Created At</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="added_at_formatted"
															label="Created At"
														></ShowValue>
													</td>
													<th class="p-2" width="250px">Service End Date (actual)</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="actual_end_date_formatted"
															label="Service End Date (actual)"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="250px">Remark</th>
													<td class="font-level-1 p-2" colspan="3">
														<ShowValue :object="service" object-key="description" label="Remark"></ShowValue>
													</td>
												</tr>
												<tr v-if="service.service_by == 2">
													<th class="p-2" width="250px">Subcon Remark</th>
													<td class="font-level-1 p-2" colspan="3">
														<ShowValue
															:object="service"
															object-key="subcon_remark"
															label="Subcon Remark"
														></ShowValue>
													</td>
												</tr>
											</table>
										</v-expansion-panel-content>
									</v-expansion-panel>
									<v-expansion-panel v-if="service.is_acknowledged == 1">
										<v-expansion-panel-header>
											<div class="d-flex align-items-center">
												<span class="font-level-3-bold">Acknowledgement</span>
												<Chip
													tooltip
													tooltip-text="Acknowledge Status"
													class="ml-4"
													:text="service.acknowledge_status_formatted"
													:color="
														service.acknowledge_status == 1
															? 'green lighten-1 white--text'
															: 'red lighten-1 white--text'
													"
												></Chip>
											</div>
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<table
												width="100%"
												class="detail-table scattered-table mt-3"
												style="table-layout: fixed"
											>
												<tr>
													<th class="p-2" width="250px">Acknowledge By</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="acknowledge_by_relation.display_name"
															label="acknowledge by"
														></ShowValue>
													</td>
													<th class="p-2" width="250px">Acknowledge At</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="acknowledge_at_formatted"
															label="acknowledge at"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="250px">Acknowledge Status</th>
													<td class="font-level-1 p-2" colspan="3">
														<Chip
															:text="service.acknowledge_status_formatted"
															:color="
																service.acknowledge_status == 1
																	? 'green lighten-1 white--text'
																	: 'red lighten-1 white--text'
															"
														></Chip>
													</td>
												</tr>
											</table>
										</v-expansion-panel-content>
									</v-expansion-panel>
									<v-expansion-panel v-if="service.is_invoiced == 1">
										<v-expansion-panel-header>
											<div class="d-flex align-items-center">
												<span class="font-level-3-bold mr-4">Invoice</span>
												<template v-if="ObjectHasKey(service, 'invoice_detail.id')">
													<Chip
														tooltip
														tooltip-text="Invoice #"
														class="mr-4"
														:text="ObjectKey(service, 'invoice_detail.barcode', 'N/A')"
														color="blue darken-4 white--text"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Invoice Status"
														class="mr-4"
														:text="ObjectKey(service, 'invoice_detail.status_relation.text', 'N/A')"
														:color="ObjectKey(service, 'invoice_detail.status_relation.color', 'N/A')"
														:text-color="ObjectKey(service, 'invoice_detail.status_relation.textcolor', 'N/A')"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Invoice Approval"
														v-if="ObjectKey(service, 'invoice_detail.is_approved', 0) == 1"
														:text="ObjectKey(service, 'invoice_detail.approve_status_formatted', 'N/A')"
														:color="
															ObjectKey(service, 'invoice_detail.approve_status', 0) == 1
																? 'green lighten-1 white--text'
																: 'red lighten-1 white--text'
														"
													></Chip>
												</template>
											</div>
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<table
												width="100%"
												class="detail-table scattered-table mt-3"
												style="table-layout: fixed"
											>
												<tr>
													<th class="p-2" width="250px">Invoice Number</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="invoice_detail.invoice_id"
															label="invoice number"
														></ShowValue>
													</td>
													<th class="p-2" width="250px">Invoice Date</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="invoice_detail.date_formatted"
															label="Invoice Date"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="250px">Total Amount</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="invoice_detail.total_amount_formatted"
															label="total amount"
														></ShowValue>
													</td>
													<th class="p-2" width="250px">Paid Amount</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="invoice_detail.paid_amount_formatted"
															label="paid amount"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="250px">Created At</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="invoice_detail.added_at_formatted"
															label="created at"
														></ShowValue>
													</td>
													<th class="p-2" width="250px">Created By</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="invoice_detail.created_by.display_name"
															label="created by"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="250px">Remark</th>
													<td class="font-level-1 p-2" colspan="3">
														<ShowValue
															:object="service"
															object-key="invoice_detail.remark"
															label="remark"
														></ShowValue>
													</td>
												</tr>
											</table>
										</v-expansion-panel-content>
									</v-expansion-panel>
									<v-expansion-panel>
										<v-expansion-panel-header>
											<div class="d-flex align-items-center">
												<span class="font-level-3-bold mr-4">Asset</span>
												<Chip
													tooltip
													tooltip-text="Asset #"
													class="mr-4"
													:text="ObjectKey(service, 'asset_relation.barcode', 'N/A')"
													color="blue darken-4 white--text"
												></Chip>
											</div>
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<table
												width="100%"
												class="detail-table scattered-table mt-3"
												style="table-layout: fixed"
											>
												<tr>
													<th class="p-2" width="300px">Asset ID</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.id_number"
															label="Asset ID"
														></ShowValue>
													</td>
													<th class="p-2" width="300px">Asset Name</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.name"
															label="Asset Name"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">System Owner</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.system_owner"
															label="System Owner"
														></ShowValue>
													</td>
													<th class="p-2" width="300px">Department</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.department"
															label="Department"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">Manufacturer</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.manufacturer"
															label="Manufacturer"
														></ShowValue>
													</td>
													<th class="p-2" width="300px">Location</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.location"
															label="Location"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">Model No.</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.model_no"
															label="Model No."
														></ShowValue>
													</td>
													<th class="p-2" width="300px">Serial No.</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.serial_no"
															label="Serial No."
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">Calibration Frequency (in months)</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.calibration_frequency"
															label="Calibration Frequency"
														></ShowValue>
													</td>
													<th class="p-2" width="300px">Last Calibration Date</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.last_calibration_date_formatted"
															label="Last Calibration Date"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">Reminder Date</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.reminder_formatted"
															label="Reminder Date"
														></ShowValue>
													</td>
													<th class="p-2" width="300px">Next Calibration Date</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.next_calibration_date_formatted"
															label="Next Calibration Date"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">Calibration Point And Tolerance</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.calibration_point_and_tolerance"
															label="Calibration Point And Tolerance"
														></ShowValue>
													</td>
													<th class="p-2" width="300px">Cost Centre (SGD)</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.cost_centre_formatted"
															label="Cost Centre"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">Calibration Location</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.calibration_location"
															label="Calibration Location"
														></ShowValue>
													</td>
													<th class="p-2" width="300px">Previous Vendor</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.previous_vendor"
															label="Previous Vendor"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">AML Proposal</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.aml_proposal"
															label="AML Proposal"
														></ShowValue>
													</td>
													<th class="p-2" width="300px">Contract Price/Quote Price (SGD)</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.price_formatted"
															label="Contract Price/Quote Price"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">BPO/PO Number</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.bpo_po_number"
															label="BPO/PO Number"
														></ShowValue>
													</td>
													<th class="p-2" width="300px">Frequency Per Year</th>
													<td class="font-level-1 p-2">
														<ShowValue
															:object="service"
															object-key="asset_relation.frequency_per_year"
															label="Frequency Per Year"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">Price Per Annum (SGD)</th>
													<td class="font-level-1 p-2" colspan="3">
														<ShowValue
															:object="service"
															object-key="asset_relation.price_per_annum_sgd_formatted"
															label="Price Per Annum"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">Process Range</th>
													<td class="font-level-1 p-2" colspan="3">
														<ShowValue
															:object="service"
															object-key="asset_relation.process_range"
															label="Process Range"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th class="p-2" width="300px">Comment</th>
													<td class="font-level-1 p-2" colspan="3">
														<ShowValue
															:object="service"
															object-key="asset_relation.comment"
															label="Comment"
														></ShowValue>
													</td>
												</tr>
											</table>
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item v-if="service.can_view" value="service-form-detail">
						<ServiceFormTemplate readonly v-model="service_form"></ServiceFormTemplate>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							:reload="reload_now"
							v-on:reload="reload_now = false"
							class="mx-4"
							type-text="Service Files"
							type="service"
							:type-uuid="service.uuid"
							c-type="5"
						></Files>
					</v-tab-item>
					<v-tab-item value="comment">
						<Comments
							class="mx-4"
							type-text="Service"
							type="service"
							:type-uuid="service.uuid"
						></Comments>
					</v-tab-item>
				</v-tabs-items>
			</div>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import Chip from "@/view/components/Chip";
import ShowValue from "@/view/components/ShowValue";
import ServiceFormTemplate from "@/view/components/ServiceFormTemplate";
import ApiService from "@/core/services/api.service";
import ObjectPath from "object-path";
import { toSafeInteger } from "lodash";

export default {
	data() {
		return {
			expansion_panel: 0,
			pageLoading: false,
			detailDialog: false,
			service: {},
			reload_now: false,
			service_form: {},
			serviceTab: null,
		};
	},
	props: {
		uuid: {
			type: String,
			default: null,
		},
		dialog: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		dialog(param) {
			if (param) {
				this.get_asset_service();
			} else {
				this.detailDialog = false;
			}
		},
	},
	methods: {
		get_asset_service() {
			ApiService.get(`asset/service/${this.uuid}`)
				.then(({ data }) => {
					this.service = data;
					this.serviceTab = "detail";
					this.service_form = data.service_form;
					this.detailDialog = true;
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				});
		},
		download_pdf() {
			window.open(this.service.pdf_url, "_blank");
		},
		convert_to_invoice() {
			const uuid = ObjectPath.get(this.service, "uuid");
			const asset_uuid = ObjectPath.get(this.service, "asset_relation.uuid");

			if (!uuid || !asset_uuid) {
				return false;
			}

			this.$router.push({
				name: "invoice-create",
				query: { service: uuid, asset: asset_uuid },
			});
		},
	},
	components: {
		Dialog,
		Comments,
		Files,
		Chip,
		ShowValue,
		ServiceFormTemplate,
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 80);
		},
	},
};
</script>
